html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.all-wrap {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.cdk-overlay-pane-override {
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1
}

.content > *:not(router-outlet) {
  flex: 1;
  display: flex;
}

.flex {
  flex: 1 1 auto;
  display: flex;
}

.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.p-5 {
  padding: 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}


.section-subtitle{
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: "Baloo Da 2";
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.1rem;
  letter-spacing: -0.005em;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  color: #444444;
  text-align: center;
}

.card-title-lg {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: "Baloo Da 2";
  font-weight: 300;
  font-size: 2rem;
  line-height: 2.2rem;
  letter-spacing: -0.005em;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  color: #444444;
  text-align: center;
}

.card-title-sm {
  -webkit-font-smoothing: antialiased;
  color: #2a2a2a;
  font-family: "Baloo Da 2";
  font-weight: 450;
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: -0.005em;
  margin-bottom: 0.5rem !important;
}

.card-subtitle {
  color: #2a2a2a;
  font-size: 1.05rem;
  font-weight: normal;
  line-height: 1.1rem;
  margin-bottom: 0.3rem !important;
}

.card-content {
  color: #2a2a2a;
}

.popup-overlay {
  z-index: 500;
}
.popup-overlay-dark {
  background: rgba(13, 47, 39, 0.8) !important;
}
.okra-container {
  position: fixed;
  top: 64px;
  bottom: 110px;
  width: 100vw;
}
.okra-container-dark {
  background: rgba(13, 47, 39, 0.8);
}

.card-container {
  background-color: #F7F7F7;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.09);
  padding: 15px;
  height: auto;
  margin-bottom: 10px;
}

.profile-sidenav-container {
  background-color: transparent !important;
  min-height: 400px;
}

.borderless-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.borderless-list li {
  cursor: pointer;
  position: relative;
  left: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.virtual-profile-list{
  width: 100%;
  position: relative;
  left: 0;
  border-bottom: solid 1px lightgray;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.virtual-profile-list:last-of-type {
  border-bottom: none;
}
.profile-container {
  padding: 10px !important;
  max-width: 1200px;
}
.profile-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
.profile-list li {
  cursor: pointer;
  position: relative;
  left: 0;
  border-bottom: solid 1px lightgray;
  padding-top: 5px;
  padding-bottom: 5px;
}
.profile-list li:last-child{
  border-bottom: none;
}
.profile-link:link, .profile-link:visited {
  color: #2a2a2a;
  text-decoration: none !important;
}
.profile-link:link:active, .profile-link:visited:active {
  text-decoration: none !important;
}
.profile-group-container {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  border: solid 1px lightgray;
  background-color: white;
}

.profile-header, .profile-content {
  margin: 0 !important;
  min-width: 100px !important;
  overflow-wrap: anywhere;
}

.product-name {
  margin-left: 10px !important;
  margin-top: 10px !important;
}

.profile-action-btn {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.profile-img {
  height: 8em;
  width: 8em;
  margin: 10px;
}

.profile-form-field {
  width: 100% !important;
}

@media screen and (max-width: 767px) {
  .cdk-overlay-pane-override {
    max-width: 90vw;
    width: 90vw;
  }
}

@media screen and (min-width: 768px) {
  .cdk-overlay-pane-override {
    width: 75vw;
  }
}

@media screen and (min-width: 1024px) {
  .cdk-overlay-pane-override {
    width: 50vw;
  }
}

@media screen and (max-width: 960px){
  .okra-container {
    top: 128px;
  }

  .okra-container-dark {
    top: 128px;
  }
}

@media screen and (max-width: 599px){
  .html {
    font-size: 13px;
  }
  .mobile-hidden{
    display: none;
  }

  .mr-10{
    margin-right: 0;
  }

  .okra-container {
    top: 112px;
  }

  .okra-container-dark {
    top: 112px;
  }

  .section-subtitle {
    font-size: 1.8rem !important;
    line-height: 2rem !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .card-title-lg {
    font-size: 1.5rem !important;
    line-height: 1.9rem !important;
  }
  .card-title-sm {
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
  }
  .card-subtitle {
    font-size: .95rem !important;
    line-height: .95rem !important;
  }
  .card-content {
    font-size: .75rem !important;
    line-height: .9rem !important;
  }
  .link-responsive {
    font-size: .75rem !important;
    line-height: .9rem !important;
  }
}

// Hide & show for different breakpoints.
.hide-xs    { @media screen and (max-width: 599px) { display: none !important; } }  // Hide on mobile.
.hide-gt-xs { @media screen and (min-width: 600px) { display: none !important; } }  // Show only on mobile. Hide on desktop.
.hide-sm    { @media screen and (max-width: 959px) { display: none !important; } }  // Hide on mobile/tablet.
.hide-gt-sm { @media screen and (min-width: 960px) { display: none !important; } }  // Show only on mobile/tablet. Hide on desktop.

.flex-row { display: flex; flex-direction: row; }
.flex-col { display: flex; flex-direction: column; }

// Justify content.
.space-between { justify-content: space-between; }
.space-around { justify-content: space-around; }
.justify-center { justify-content: center; }
.justify-start { justify-content: flex-start; }  
.justify-end { justify-content: flex-end; }  
.space-evenly { justify-content: space-evenly; }

.items-center { align-items: center; }
.items-start { align-items: start; }
.items-end { align-items: end; }
.items-stretch { align-items: stretch };

.flex-1 { flex: 1; }  // Same as flex: 1 1 0 (grow, shrink, basis 0). Has similar effect to width: 100%;
.flex-auto { flex: 1 1 auto;}
.flex-grow { flex: 1 1 auto; }  // Same as flex: 1 1 auto (grow, shrink, basis auto). For spacer, etc. 
.flex-basis { flex-basis: 0;}
.flex-shrnk {
  flex-shrink: 0;
  flex-grow: 1;
}
.flex-100 { flex: 1 1 100% }
.flex-20 { flex: 1 1 20% }
.flex-25 { flex: 1 1 25% }
.flex-50 { flex: 1 1 50% }
.flex-60 { flex: 1 1 60% }
.flex-30 { flex: 1 1 30% }
.flex-75 { flex: 1 1 75% }

.flex-sm-100 { @media screen and (max-width: 959px) { flex: 1 1 100%; } }  
// Flex-wrap utils.
.flex-wrap { flex-wrap: wrap; }  // Mostly used with flex-row, when wrapping is desired.
.flex-col-sm { @media screen and (max-width: 959px) { flex-direction: column; } }  // Switch from flex-row to flex-col on tablet.
.flex-row-sm { @media screen and (max-width: 959px) { flex-direction: row; } }  // Switch from flex-col to flex-row on mobile.

.flex-row-xs { @media screen and (max-width: 960px) { flex-direction: row; } }  // Switch from flex-row to flex-col on mobile.
.flex-col-xs { @media screen and (max-width: 960px) { flex-direction: column; } }  // Switch from flex-row to flex-col on mobile.

.flex-sm-start { @media screen and (max-width: 959px) { justify-content: flex-start; } }  
.flex-sm-space { @media screen and (max-width: 959px) { justify-content: space-between; } }  
.flex-sm-center { @media screen and (max-width: 959px) { justify-content: center; } } 
.flex-sm-itemscenter { @media screen and (max-width: 959px) { align-items: center; } }  
.flex-sm-itemsstart { @media screen and (max-width: 959px) { align-items: start; } } 

.flex-order-1 { order:1}
.flex-order-2 { order:2}
.flex-order-sm-1 { @media screen and (max-width: 959px) {order:1}}
.flex-order-sm-2 { @media screen and (max-width: 959px) {order:2}}

.flex-col-gap-50{column-gap: 50px;}
.flex-row-gap-25{row-gap: 25px;}
