@use '@angular/material' as mat;
@import '@angular/material/theming';

// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
/* For use in src/lib/core/theming/_palette.scss */
$md-okradark: (
    50 : #e5e8e7,
    100 : #bec5c3,
    200 : #929e9b,
    300 : #667772,
    400 : #465a54,
    500 : #253d36,
    600 : #213730,
    700 : #1b2f29,
    800 : #162722,
    900 : #0d1a16,
    A100 : #5dffc9,
    A200 : #2affb8,
    A400 : #00f6a4,
    A700 : #00dc93,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-okragold: (
    50 : #fefdf3,
    100 : #fdfae2,
    200 : #fcf7cf,
    300 : #faf4bc,
    400 : #f9f1ad,
    500 : #f8ef9f,
    600 : #f7ed97,
    700 : #f6eb8d,
    800 : #f5e883,
    900 : #f3e472,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #fffdee,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-okragreen: (
    50 : #e9ede5,
    100 : #c9d3bf,
    200 : #a5b594,
    300 : #819769,
    400 : #668149,
    500 : #4b6b29,
    600 : #446324,
    700 : #3b581f,
    800 : #334e19,
    900 : #233c0f,
    A100 : #b0ff77,
    A200 : #93ff44,
    A400 : #75ff11,
    A700 : #68f600,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-salmon: (
    50 : #ffefea,
    100 : #ffd7cb,
    200 : #ffbca8,
    300 : #ffa185,
    400 : #ff8d6b,
    500 : #ff7951,
    600 : #ff714a,
    700 : #ff6640,
    800 : #ff5c37,
    900 : #ff4927,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffdad4,
    A700 : #ffc4bb,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// mandatory stuff for theming
$okra-palette-salmon: mat.define-palette($md-salmon);
$okra-palette-dark: mat.define-palette($md-okradark);
$okra-palette-gold: mat.define-palette($md-okragold);
$okra-palette-green: mat.define-palette($md-okragreen);

$okra-typography: mat.define-legacy-typography-config(
    $font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $display-4: mat.define-typography-level(112px, 112px, 300, '"Baloo Da 2"', $letter-spacing: -0.05em),
    $display-3: mat.define-typography-level(82px, 82px, 400, '"Baloo Da 2"', $letter-spacing: -0.02em),
    $display-2: mat.define-typography-level(60px, 65px, 400, '"Baloo Da 2"', $letter-spacing: -0.005em),
    $display-1: mat.define-typography-level(34px, 40px, 400, '"Baloo Da 2"'),
    $headline: mat.define-typography-level(24px, 32px, 400, '"Baloo Da 2"'),
    $title: mat.define-typography-level(20px, 32px, 500, '"Baloo Da 2"'),
);


// include the custom theme components into a theme object
$okra-theme: mat.define-light-theme((
    color: (
        primary: $okra-palette-green, 
        accent:  $okra-palette-gold, 
        warn: $okra-palette-salmon,
        ),
        typography: $okra-typography,
));

// include the custom theme object into the angular material theme
@include mat.all-legacy-component-themes($okra-theme);
// @include mat.core($okra-typography);

$primary: map-get($okra-theme, primary);
$accent: map-get($okra-theme, accent);
$warn: map-get($okra-theme, warn);

:root {
    --color-okra-dark: #{mat.get-color-from-palette($okra-palette-dark)};
    --color-okra-gold: #{mat.get-color-from-palette($okra-palette-gold)};
    --color-okra-gold-900: #{mat.get-color-from-palette($okra-palette-gold, 900)};
    --color-okra-green: #{mat.get-color-from-palette($okra-palette-green)};
}
